import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Crypto = () => (
  <Layout>
    <SEO title="crypto" />
    <h1>Crypto</h1>

    <Image alt="bitcoin" filename="bitcoin.jpg"/>
  </Layout>
)

export default Crypto